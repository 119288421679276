@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 125%;
}

@media (max-width: 1500px) {
  html {
    font-size: 100%
  }
}

@media (max-width: 1100px) {
  html {
    font-size: 92%
  }
}

@media (max-width: 980px) {
  html {
    font-size: 100%
  }
}

body {
  font-family: 'Proxima Nova', sans-serif;
  font-size: 16px;
  @apply text-slate-800;
}

.input-group {
  @apply flex flex-col w-full text-left
}

.input-group.input-half > div:first-child {
  @apply mr-4
}

.input-group.input-half > div:last-child {
  @apply mr-4
}
 
.input-group label {
  @apply text-sm font-medium text-slate-500 mb-1
}

.cm-input {
  @apply border border-slate-300 relative w-full overflow-hidden rounded-lg bg-white text-left shadow-md text-base md:text-sm min-h-[40px] px-2 xl:px-3
}

.cm-input.blue {
  @apply bg-blue-500 text-white
}

.cm-input.disabled {
  @apply bg-gray-200 cursor-not-allowed
}

.btn {
  @apply rounded px-8 py-3 font-medium max-w-3xl
}

.btn.small {
  @apply px-4 py-2 text-sm
}

.required {
  @apply text-red-500 text-lg ml-0.5
}

.fade-in{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
}

@keyframes fadein {
 from { opacity: 0; }
 to   { opacity: 1; }
}

.checkmark {
  width: 200px;
  margin: 0 auto;
  padding-top: 40px;
}

@media (max-width: 640px) {
  .checkmark {
    width: 120px;
    margin: 0 auto;
    padding-top: 20px;
  }
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}